import React from 'react';

import { Flex, Form, FormInstance } from 'antd';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { ProfilePhotoWithName } from 'components/filter/ProfilePhotoWithName';
import InputFields from 'components/input-fields';
import { EInputFieldTypes } from 'components/input-fields/types/enums';
import { TFormFields } from 'components/input-fields/types/types';
import { COUNTRY_DETAILS, DEFAULT_ITEMS_LIMIT_PER_PAGE, IMAGE_ASPECT_RATIOS, STATUS } from 'config/constants';
import { API_URLS } from 'services/apiUrls';
import { EGenres } from 'types/enums';

import { useFilterForm } from './useFilterForm';
import { DEFAULT_FILTER_SORT_BY, DEFAULT_FILTER_SORT_ORDER, DEFAULT_GLOBAL_FILTER_SORT_ORDER_LIST, DRM_FILTER_OPTIONS } from '../constants';
import { EFilterNames, TGlobalFilterConfig } from '../types';

interface TProps {
	form: FormInstance;
	filterConfig: TGlobalFilterConfig;
}

const GlobalFilterForm = (props: TProps) => {
	const { t } = useTranslation();
	const { form, filterConfig } = props;

	const predefinedFormFields = getPredefinedFormFields({
		formFields: filterConfig.formFields,
		t
	});

	const { initialValues, finalFormFields } = useFilterForm({
		filterConfig,
		predefinedFormFields
	});

	return (
		<Form form={form} initialValues={initialValues} className='max-h-[29rem] overflow-y-scroll'>
			{finalFormFields &&
				Object.keys(finalFormFields).map((formFieldKey, index: number) => {
					const fieldType = finalFormFields[formFieldKey]?.fieldType;
					const isFormItemVertical = fieldType === EInputFieldTypes.DATE_RANGE_PICKER;

					return (
						<React.Fragment key={formFieldKey}>
							{index !== 0 && <div className='h-2 bg-gray-200' />}
							<Flex
								className='px-4 py-3'
								justify='space-between'
								align={isFormItemVertical ? 'start' : 'center'}
								vertical={isFormItemVertical}
								gap={isFormItemVertical ? 8 : 0}
							>
								<div className='text-[13px] font-semibold'>{finalFormFields[formFieldKey].formItemProps?.label as string}</div>

								<div className={`${isFormItemVertical ? 'w-full' : 'w-52'}`}>
									<InputFields
										formFields={{
											[formFieldKey]: {
												...finalFormFields[formFieldKey],
												formItemProps: {
													...finalFormFields[formFieldKey]?.formItemProps,
													label: '',
													style: {
														...finalFormFields[formFieldKey].formItemProps?.style,
														marginBottom: 0
													}
												},
												fieldProps: {
													...finalFormFields[formFieldKey].fieldProps,
													size: 'middle',

													...(finalFormFields[formFieldKey].fieldType === EInputFieldTypes.PILLS && { formInstance: form })
												} as any
											}
										}}
									/>
								</div>
							</Flex>
						</React.Fragment>
					);
				})}
		</Form>
	);
};

export default GlobalFilterForm;

type TGetFormFields = {
	formFields: TGlobalFilterConfig['formFields'];
	t: TFunction;
};
const getPredefinedFormFields = ({ formFields, t }: TGetFormFields): TFormFields => {
	return {
		[EFilterNames.RECORD_LIMIT]: {
			fieldType: EInputFieldTypes.NUMBER,
			colProps: {
				...formFields?.predefined?.[EFilterNames.RECORD_LIMIT]?.colProps
			},
			formItemProps: {
				label: t('filter.records'),
				extra: '',
				name: EFilterNames.RECORD_LIMIT,

				...formFields?.predefined?.[EFilterNames.RECORD_LIMIT]?.formItemProps
			},
			fieldProps: {
				min: 1,
				placeholder: t('filter.enterRecordsLimit'),
				defaultValue: DEFAULT_ITEMS_LIMIT_PER_PAGE,

				...formFields?.predefined?.[EFilterNames.RECORD_LIMIT]?.fieldProps
			}
		},

		[EFilterNames.SORT_BY]: {
			fieldType: EInputFieldTypes.SELECT,
			colProps: {
				...formFields?.predefined?.[EFilterNames.SORT_BY]?.colProps
			},
			formItemProps: {
				label: t('filter.sortBy'),
				name: EFilterNames.SORT_BY,

				...formFields?.predefined?.[EFilterNames.SORT_BY]?.formItemProps
			},
			fieldProps: {
				placeholder: t('filter.selectSortBy'),
				defaultValue: DEFAULT_FILTER_SORT_BY,

				...formFields?.predefined?.[EFilterNames.SORT_BY]?.fieldProps
			}
		},

		[EFilterNames.SORT_ORDER]: {
			fieldType: EInputFieldTypes.RADIO_GROUP,
			colProps: {
				...formFields?.predefined?.[EFilterNames.SORT_ORDER]?.colProps
			},
			formItemProps: {
				label: t('filter.sortOrder'),
				name: EFilterNames.SORT_ORDER,

				...formFields?.predefined?.[EFilterNames.SORT_ORDER]?.formItemProps
			},
			fieldProps: {
				className: 'flex justify-end',
				options: DEFAULT_GLOBAL_FILTER_SORT_ORDER_LIST,
				defaultValue: DEFAULT_FILTER_SORT_ORDER,

				...formFields?.predefined?.[EFilterNames.SORT_ORDER]?.fieldProps
			}
		},

		[EFilterNames.DATE_RANGE]: {
			fieldType: EInputFieldTypes.DATE_RANGE_PICKER,
			colProps: {
				...formFields?.predefined?.[EFilterNames.DATE_RANGE]?.colProps
			},
			formItemProps: {
				label: t('filter.dateRange'),
				name: EFilterNames.DATE_RANGE,
				...formFields?.predefined?.[EFilterNames.DATE_RANGE]?.formItemProps
			},
			fieldProps: {
				placeholder: [t('filter.startDate'), t('filter.endDate')],
				...formFields?.predefined?.[EFilterNames.DATE_RANGE]?.fieldProps
			}
		},

		[EFilterNames.STATE]: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			api: {
				url: `${API_URLS.MASTER_STATES}/${COUNTRY_DETAILS.IN.value}`,
				labelKey: 'name',
				valueKey: 'id',
				responseDataStructure: 'data',
				...formFields?.predefined?.[EFilterNames.STATE]?.api
			},
			colProps: {
				...formFields?.predefined?.[EFilterNames.STATE]?.colProps
			},
			formItemProps: {
				label: t('filter.state'),
				name: EFilterNames.STATE,
				...formFields?.predefined?.[EFilterNames.STATE]?.formItemProps
			},
			fieldProps: {
				placeholder: t('filter.selectState'),
				...formFields?.predefined?.[EFilterNames.STATE]?.fieldProps
			}
		},

		[EFilterNames.DISTRICT]: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			api: {
				url: API_URLS.MASTER_DISTRICTS,
				labelKey: 'name',
				valueKey: 'id',
				responseDataStructure: 'data',
				...formFields?.predefined?.[EFilterNames.DISTRICT]?.api
			},
			colProps: {
				...formFields?.predefined?.[EFilterNames.DISTRICT]?.colProps
			},
			formItemProps: {
				label: t('filter.district'),
				name: EFilterNames.DISTRICT,
				...formFields?.predefined?.[EFilterNames.DISTRICT]?.formItemProps
			},
			fieldProps: {
				placeholder: t('filter.selectDistricts'),
				...formFields?.predefined?.[EFilterNames.DISTRICT]?.fieldProps
			}
		},

		[EFilterNames.CATEGORY]: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			api: {
				url: API_URLS.ALL_CATEGORIES,
				labelKey: 'title',
				valueKey: 'id',
				responseDataStructure: 'data',
				...formFields?.predefined?.[EFilterNames.CATEGORY]?.api
			},
			colProps: {
				...formFields?.predefined?.[EFilterNames.CATEGORY]?.colProps
			},
			formItemProps: {
				label: t('filter.category'),
				name: EFilterNames.CATEGORY,
				...formFields?.predefined?.[EFilterNames.CATEGORY]?.formItemProps
			},
			fieldProps: {
				placeholder: t('filter.selectCategory'),
				...formFields?.predefined?.[EFilterNames.CATEGORY]?.fieldProps
			}
		},

		[EFilterNames.PARTY]: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			api: {
				url: API_URLS.PARTY_ALL,
				labelKey: 'title',
				valueKey: 'id',
				responseDataStructure: 'data',
				params: { limit: undefined, page: undefined },
				...formFields?.predefined?.[EFilterNames.PARTY]?.api
			},
			colProps: {
				...formFields?.predefined?.[EFilterNames.PARTY]?.colProps
			},
			formItemProps: {
				label: t('filter.party'),
				name: EFilterNames.PARTY,
				...formFields?.predefined?.[EFilterNames.PARTY]?.formItemProps
			},
			fieldProps: {
				placeholder: t('filter.selectParty'),
				...formFields?.predefined?.[EFilterNames.PARTY]?.fieldProps
			}
		},

		[EFilterNames.GENRE]: {
			fieldType: EInputFieldTypes.SELECT,
			colProps: {
				...formFields?.predefined?.[EFilterNames.GENRE]?.colProps
			},
			formItemProps: {
				label: t('filter.genre'),
				name: EFilterNames.GENRE,

				...formFields?.predefined?.[EFilterNames.GENRE]?.formItemProps
			},
			fieldProps: {
				placeholder: t('filter.selectGenre'),
				options: Object.values(EGenres).map((genre) => ({
					label: genre,
					value: genre
				})),
				...formFields?.predefined?.[EFilterNames.GENRE]?.fieldProps
			}
		},

		[EFilterNames.CONTENT_STATUS]: {
			fieldType: EInputFieldTypes.SELECT,
			colProps: {
				...formFields?.predefined?.[EFilterNames.CONTENT_STATUS]?.colProps
			},
			formItemProps: {
				label: t('filter.status'),
				name: EFilterNames.CONTENT_STATUS,

				...formFields?.predefined?.[EFilterNames.CONTENT_STATUS]?.formItemProps
			},
			fieldProps: {
				placeholder: t('filter.selectStatus'),
				options: STATUS,
				...formFields?.predefined?.[EFilterNames.CONTENT_STATUS]?.fieldProps,
				allowClear: true
			}
		},

		[EFilterNames.TAG]: {
			fieldType: EInputFieldTypes.TAG,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
			tagType: formFields?.predefined?.[EFilterNames.TAG]?.tagType!,
			colProps: {
				...formFields?.predefined?.[EFilterNames.TAG]?.colProps
			},
			formItemProps: {
				label: 'Tags',
				name: EFilterNames.TAG,

				...formFields?.predefined?.[EFilterNames.TAG]?.formItemProps
			},
			fieldProps: {
				...formFields?.predefined?.[EFilterNames.TAG]?.fieldProps
			}
		},
		[EFilterNames.DRM_PROTECTED]: {
			fieldType: EInputFieldTypes.RADIO_GROUP,
			colProps: {
				...formFields?.predefined?.[EFilterNames.DRM_PROTECTED]?.colProps
			},
			formItemProps: {
				label: t('filter.drmProtected'),
				name: EFilterNames.DRM_PROTECTED,
				className: 'flex justify-end',
				...formFields?.predefined?.[EFilterNames.DRM_PROTECTED]?.formItemProps
			},
			fieldProps: {
				options: DRM_FILTER_OPTIONS,
				defaultValue: false,
				...formFields?.predefined?.[EFilterNames.DRM_PROTECTED]?.fieldProps,
				className: 'flex justify-end'
			}
		},
		[EFilterNames.IMAGE_TYPE]: {
			fieldType: EInputFieldTypes.SELECT,
			colProps: {
				...formFields?.predefined?.[EFilterNames.IMAGE_TYPE]?.colProps
			},
			formItemProps: {
				label: t('filter.imageType'),
				name: EFilterNames.IMAGE_TYPE,
				...formFields?.predefined?.[EFilterNames.IMAGE_TYPE]?.formItemProps
			},
			fieldProps: {
				placeholder: t('filter.selectImageType'),
				options: IMAGE_ASPECT_RATIOS,
				...formFields?.predefined?.[EFilterNames.IMAGE_TYPE]?.fieldProps
			}
		},
		[EFilterNames.UPDATED_BY]: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			api: {
				url: API_URLS.GET_ALL_CMS_USER,
				labelKey: 'profile',
				valueKey: 'id',
				responseDataStructure: 'items',
				getOptionLabel: (item: any) => {
					return ProfilePhotoWithName({
						roles: item?.roles,
						imageUrl: item?.profile?.imageUrl,
						firstName: item?.profile?.firstName,
						lastName: item?.profile?.lastName
					});
				},
				...formFields?.predefined?.[EFilterNames.UPDATED_BY]?.api
			},
			colProps: {
				...formFields?.predefined?.[EFilterNames.UPDATED_BY]?.colProps
			},
			formItemProps: {
				label: 'Updated By',
				name: EFilterNames.UPDATED_BY,
				...formFields?.predefined?.[EFilterNames.UPDATED_BY]?.formItemProps
			},
			fieldProps: {
				className: 'h-fit',
				placeholder: 'Select User',
				allowClear: true,
				showSearch: true,
				...formFields?.predefined?.[EFilterNames.UPDATED_BY]?.fieldProps
			}
		},
		[EFilterNames.CREATED_BY]: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			api: {
				url: API_URLS.GET_ALL_CMS_USER,
				labelKey: 'profile',
				valueKey: 'id',
				responseDataStructure: 'items',
				getOptionLabel: (item: any) => {
					return ProfilePhotoWithName({
						roles: item?.roles,
						imageUrl: item?.profile?.imageUrl,
						firstName: item?.profile?.firstName,
						lastName: item?.profile?.lastName
					});
				},
				...formFields?.predefined?.[EFilterNames.CREATED_BY]?.api
			},
			colProps: {
				...formFields?.predefined?.[EFilterNames.CREATED_BY]?.colProps
			},
			formItemProps: {
				label: 'Created By',
				name: EFilterNames.CREATED_BY,
				...formFields?.predefined?.[EFilterNames.CREATED_BY]?.formItemProps
			},
			fieldProps: {
				className: 'h-fit',
				placeholder: 'Select User',
				allowClear: true,
				showSearch: true,
				...formFields?.predefined?.[EFilterNames.CREATED_BY]?.fieldProps
			}
		}
	};
};
