// eslint-disable-next-line import/no-cycle
import AudioGallery from './audio/AudioGallery';
import ImageGallery from './image/ImageGallery';
import { EMediaType, TMediaGallery } from './types';
import VideoGallery from './video/VideoGallery';

const MEDIA_TYPE_GALLERY_COMPONENT_HASH = {
	[EMediaType.video]: VideoGallery,
	[EMediaType.image]: ImageGallery,
	[EMediaType.audio]: AudioGallery
};

const MediaGallery = ({ mediaType, ...restProps }: TMediaGallery) => {
	if (!mediaType) return null;

	const GalleryComponent = MEDIA_TYPE_GALLERY_COMPONENT_HASH[mediaType];

	if (!GalleryComponent) return null;

	return <GalleryComponent key={mediaType} {...restProps} />;
};

export default MediaGallery;
