import { useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Flex, Form, FormInstance, Spin } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import PhoneInput from 'react-phone-input-2';

import MetaInputFields from 'components/form/page-meta-details-form/meta-input-fields';
import { reflectTitleToMeta } from 'components/form/page-meta-details-form/util';
import Header from 'components/header';
import HeaderTranslation from 'components/header-translations';
import InputFields from 'components/input-fields';
import { EInputFieldTypes } from 'components/input-fields/types/enums';
import { TFormFields } from 'components/input-fields/types/types';
import RichTextEditor from 'components/Inputs/RichTextEditor';
import { FORM_SCROLL_OPTIONS } from 'config/constants';
import { useUtils } from 'contexts/UtilsProvider';
import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { useRolePermissions } from 'services/user-role/permissions/useRolePermissions';

import 'react-phone-input-2/lib/style.css';

export enum InfoPageType {
	ABOUT = 'about',
	CONTACT = 'contact',
	PRIVACY_POLICY = 'privacy-policy'
}

type Props = {
	infoPageType: InfoPageType;
};
const InfoPage = ({ infoPageType }: Props) => {
	const { showError, showSuccess } = useUtils();
	const [isMultiLang, setIsMultiLang] = useState(false);
	const [language, setLanguage] = useState('ml');
	const [pageId, setPageId] = useState('');

	const { hasPermission } = useRolePermissions();

	const title = () => {
		let text = '';
		if (infoPageType === InfoPageType.ABOUT) text = 'About Us';
		if (infoPageType === InfoPageType.CONTACT) text = 'Contact Us';
		if (infoPageType === InfoPageType.PRIVACY_POLICY) text = 'Privacy Policy';
		return text;
	};
	const [form] = Form.useForm();

	const [isEdit, setIsEdit] = useState(false);
	const disabled = useMemo(() => !isEdit || !hasPermission('pages.edit'), [hasPermission, isEdit]);
	const formFields = getFormFields({ form, infoPageType, disabled });

	// todo remove id
	/**
 * guys. change permissions -
create/edit - content creator
list- viewer 
@Logan
 
@Irsad Ahmad
 *  */
	const onAddTranslation = () => {
		setIsMultiLang(true);
	};

	const handleEdit = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setIsEdit(true);
	};

	const onCancel = () => {
		form.resetFields();
		setIsEdit(false);
		setIsMultiLang(false);
	};
	const header = { headers: { 'accept-language': isMultiLang ? language : '' } };

	const queryPage = useQuery({
		queryKey: [isMultiLang, header, API_URLS.PAGES, infoPageType, pageId],
		queryFn: () =>
			HttpService.get(`${API_URLS.PAGES}${isMultiLang ? `/${pageId}` : ''}`, {}, header)
				.then(({ data }) => {
					if (isMultiLang) return data?.data;
					return data?.data?.find?.((e: any) => e?.pageType === infoPageType) || {};
				})
				.catch(async (err) => {
					if (err !== 'No pages found') throw err;
					showError(`Please create ${infoPageType}`);
					return {};
				})
	});

	useEffect(() => {
		if (queryPage.data) {
			form.setFieldsValue({ ...queryPage.data });
			queryPage.data?.id && setPageId(queryPage.data?.id);
		}
	}, [queryPage.data]);

	const onSuccess = ({ data: { message } }: any) => {
		showSuccess(message);
		setIsEdit(false);
		setIsMultiLang(false);
		queryPage.refetch();
	};
	const createPage = useMutation({
		mutationFn: (data: any) => HttpService.post(API_URLS.PAGES, { ...data, pageType: infoPageType }),
		onSuccess
	});
	const updatePage = useMutation({
		mutationFn: (data: any) =>
			HttpService.patch(
				`${API_URLS.PAGES}/${pageId}${isMultiLang ? '/translations' : ''}`,
				{ ...data, pageType: infoPageType },
				{},
				header
			),
		onSuccess
	});

	const onSubmit = (formValues: any) => {
		if (!pageId) createPage.mutate(formValues);
		else updatePage.mutate(formValues);
	};

	const isLoading = useMemo(
		() => queryPage.isLoading || updatePage.isPending || createPage.isPending,
		[createPage.isPending, queryPage.isLoading, updatePage.isPending]
	);

	return (
		<Flex vertical gap={16}>
			<Header
				title={title()}
				subTitle={hasPermission('pages.create') && isMultiLang ? 'Add Tarnation' : `Manage ${title()}`}
				renderRight={
					isMultiLang && (
						<HeaderTranslation initLanguage={language} languageSetter={setLanguage} disabledLanguage={['en']} />
					)
				}
			/>

			<Spin spinning={isLoading}>
				<Form
					layout='vertical'
					form={form}
					scrollToFirstError={FORM_SCROLL_OPTIONS}
					onFinish={onSubmit}
					disabled={disabled}
				>
					<Flex vertical gap={16}>
						{isMultiLang ? (
							<InputFields formFields={getMultiLangFormFields(disabled, infoPageType)} />
						) : (
							<>
								<InputFields formFields={formFields} />
								{infoPageType === InfoPageType.CONTACT && <InputFields formFields={getContactFormFields(disabled)} />}
								<div className='p-4 my-3 bg-zinc-50'>
									<MetaInputFields form={form} />
								</div>
							</>
						)}
						<ButtonGroup className='gap-4 [&>button]:rounded-md'>
							{isEdit ? (
								<>
									<Button size='large' type='default' block loading={false} onClick={onCancel}>
										Cancel
									</Button>
									{!isMultiLang && (
										<Button
											size='large'
											type='primary'
											block
											loading={false}
											disabled={disabled || !pageId}
											onClick={() => onAddTranslation()}
										>
											Add Translation
										</Button>
									)}

									<Button size='large' type='primary' htmlType='submit' block loading={false}>
										{pageId ? `Update` : `Save`}
									</Button>
								</>
							) : (
								<Button
									size='large'
									type='primary'
									block
									onClick={handleEdit}
									disabled={!hasPermission('pages.edit')}
									loading={isLoading}
								>
									{pageId ? 'Edit' : 'Create'}
								</Button>
							)}
						</ButtonGroup>
					</Flex>
				</Form>
			</Spin>
		</Flex>
	);
};

export default InfoPage;

const getMultiLangFormFields = (disabled: boolean, infoPageType: InfoPageType): TFormFields => {
	const temp: TFormFields = {
		title: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 24 },
			formItemProps: {
				name: 'title',
				label: 'Title',
				rules: [{ required: true, message: 'Please enter Title' }]
			},
			fieldProps: {
				placeholder: 'Enter Title'
			}
		},
		address: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 24 },
			formItemProps: {
				name: 'address',
				label: 'Address',
				rules: [{ required: true, message: 'Please enter Address' }]
			},
			fieldProps: {
				placeholder: 'Enter Address'
			}
		},
		content: {
			fieldType: EInputFieldTypes.CUSTOM,
			colProps: { span: 24 },
			formItemProps: {
				name: 'content',
				label: 'Content',
				rules: [{ required: true, message: 'Please enter content' }]
			},
			fieldProps: {
				render: () => (
					<RichTextEditor config={{ placeholder: 'Write your content content' }} useMedia={false} disabled={disabled} />
				)
			}
		}
	};
	if (infoPageType !== InfoPageType.CONTACT) delete temp.address;
	return temp;
};

const getFormFields = ({
	form,
	disabled,
	infoPageType
}: {
	form: FormInstance;
	infoPageType?: InfoPageType;
	disabled: boolean;
}): TFormFields => {
	const temp: TFormFields = {
		title: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 24 },
			formItemProps: {
				name: 'title',
				label: 'Title',
				rules: [{ required: true, message: 'Please enter Title' }]
			},
			fieldProps: {
				placeholder: 'Enter Title',
				onChange: (e) => {
					reflectTitleToMeta(form, e);
				}
			}
		},
		content: {
			fieldType: EInputFieldTypes.CUSTOM,
			colProps: { span: 24 },
			formItemProps: {
				name: 'content',
				label: 'Content',
				rules: [{ required: true, message: 'Please enter description' }]
			},
			fieldProps: {
				render: () => (
					<RichTextEditor
						config={{ placeholder: 'Write your content description' }}
						useMedia={false}
						disabled={disabled}
					/>
				)
			}
		}
	};
	if (infoPageType === InfoPageType.CONTACT) delete temp.editor;

	return temp;
};

const getContactFormFields = (disabled: boolean): TFormFields => {
	return {
		email: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 12 },
			formItemProps: {
				name: 'email',
				label: 'Email',
				rules: [{ required: true, message: 'Please enter Email', type: 'email' }]
			},
			fieldProps: {
				placeholder: 'Enter Email'
			}
		},
		phone: {
			fieldType: EInputFieldTypes.CUSTOM,
			colProps: { span: 12 },
			formItemProps: {
				name: 'phone',
				label: 'Phone',
				rules: [{ required: true, message: 'Please enter Phone' }]
			},
			fieldProps: {
				// placeholder: 'Enter Phone',
				render: () => (
					<PhoneInput
						inputProps={{
							name: 'phone',
							required: true
						}}
						containerStyle={{
							width: '100%'
						}}
						inputStyle={{
							width: '100%'
							// backgroundColor: isUserUpdating ? 'var(--color-gray-disabled)' : undefined
						}}
						country='in'
						enableSearch
						disabled={disabled}
					/>
				)
			}
		},
		address: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 24 },
			formItemProps: {
				name: 'address',
				label: 'Address',
				rules: [{ required: true, message: 'Please enter Address' }]
			},
			fieldProps: {
				placeholder: 'Enter Address'
			}
		},
		latitude: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 12 },
			formItemProps: {
				name: 'latitude',
				label: 'Latitude',

				rules: [{ required: true, message: 'Please enter Latitude' }]
			},
			fieldProps: {
				placeholder: 'Enter Latitude',
				type: 'number'
			}
		},
		longitude: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 12 },
			formItemProps: {
				name: 'longitude',
				label: 'Longitude',
				rules: [{ required: true, message: 'Please enter Longitude' }]
			},
			fieldProps: {
				placeholder: 'Enter Longitude',
				type: 'number'
			}
		}
	};
};
