import { ReactNode } from 'react';

import { Avatar, Button, Card, Col, Flex, Image, Row, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { MdOutlineAudiotrack } from 'react-icons/md';

import './styles.scss';
import { convertToTitleCase } from 'helpers';
import { CiLock } from 'react-icons/ci';

type TCustomCardType = {
	cardType: 'custom';
	renderCardItem: (item: any) => ReactNode;
};

type TProps = {
	selectable?: boolean;
	data: { id: string; [key: string]: any }[];
	selectedItems?: string[];
	onSelect: (id: string) => void;
} & ({ cardType: 'image' | 'video' | 'audio' } | TCustomCardType);

const CardGallery = (props: TProps) => {
	const { cardType, data, selectable = false, selectedItems = [], onSelect } = props;

	if (data.length === 0) return null;

	return (
		<Flex className='card-gallery__container'>
			<Row gutter={[16, 16]} style={{ padding: '4px', width: '100%' }}>
				{data.map((item) => {
					let isSelectable = selectable;
					if (isSelectable && cardType === 'video') {
						isSelectable = item?.processStatus === 'completed';
					}

					return (
						<Col span={8} key={item.id}>
							<ItemContainer
								selectable={isSelectable}
								selected={selectedItems.includes(item.id)}
								onSelect={() => selectable && onSelect(item.id)}
							>
								{cardType === 'image' && <ImageCard data={item} />}
								{cardType === 'video' && <VideoCard data={item} />}
								{cardType === 'audio' && <AudioCard data={item} />}
								{/* // todo where is type? */}
								{/* // eslint-disable-next-line react/destructuring-assignment */}
								{cardType === 'custom' && props.renderCardItem(item)}
							</ItemContainer>
						</Col>
					);
				})}
			</Row>
		</Flex>
	);
};

export default CardGallery;

type TItemContainer = {
	selectable?: boolean;
	selected?: boolean;
	onSelect: () => void;
	children: ReactNode;
};

const ItemContainer = ({ selectable = false, selected = false, onSelect, children }: TItemContainer) => {
	const className = [
		'card-gallery__item',
		selectable ? 'card-gallery__item--selectable' : '',
		selected ? 'card-gallery__item--selected' : ''
	].join(' ');

	return (
		<div className={className} onClick={onSelect} style={{ zIndex: 0 }}>
			{selectable && (
				<Button
					className='btn--select'
					icon={<CheckOutlined />}
					size='small'
					// danger={selected}
					type={selected ? 'primary' : 'default'}
					style={{ borderRadius: '50%' }}
				/>
			)}

			{children}
		</div>
	);
};

const ImageCard = ({ data }: { data: any }) => {
	return (
		<Card
			cover={<Image alt={data.title} src={data.urls.url} height={200} preview={false} className='object-fill' />}
			bordered={false}
			styles={{
				cover: {
					boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
					borderRadius: 8,
					overflow: 'hidden',
					margin: 1
				},
				body: { padding: 4 }
			}}
			style={{ overflow: 'hidden', boxShadow: 'none' }}
		>
			<Typography.Paragraph
				ellipsis={{ tooltip: data.title, rows: 1 }}
				style={{ margin: 0, textTransform: 'capitalize', textAlign: 'center' }}
			>
				{data.title || '---'}
			</Typography.Paragraph>
		</Card>
	);
};

const VideoCard = ({ data }: { data: any }) => {
	return (
		<Card
			cover={
				<Flex className='relative'>
					{data.processStatus !== 'completed' && (
						<div className='z-10 absolute top-2 left-2 bg-white bg-opacity-75 p-1 text-[#ff0000] text-[12px] rounded-md shadow'>
							{convertToTitleCase(data.processStatus)}
						</div>
					)}
					{data.videoSetting?.drmProtected && (
						<CiLock className='z-10 absolute top-0 left-0 m-2 bg-white bg-opacity-75 shadow-md p-1 text-red-900 rounded-sm' size={25} />
					)}
					<Image alt={data.title} src={data.urls?.image?.thumbnail} height={200} width='100%' preview={false} />
				</Flex>
			}
			bordered={false}
			styles={{
				cover: {
					boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
					borderRadius: 8,
					overflow: 'hidden',
					margin: 1
				},
				body: { padding: 4 }
			}}
			style={{ overflow: 'hidden', boxShadow: 'none' }}
		>
			<Typography.Paragraph
				ellipsis={{ tooltip: data.title, rows: 1 }}
				style={{ margin: 0, textTransform: 'capitalize', textAlign: 'center' }}
			>
				{data.title || '---'}
			</Typography.Paragraph>
		</Card>
	);
};

const AudioCard = ({ data }: { data: any }) => {
	return (
		<Card
			cover={
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Avatar icon={<MdOutlineAudiotrack />} size={100} />
				</div>
			}
			bordered={false}
			styles={{
				cover: {
					height: 200,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',

					boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
					borderRadius: 8,
					overflow: 'hidden',
					margin: 1
				},
				body: { padding: 4 }
			}}
			style={{ overflow: 'hidden', boxShadow: 'none' }}
		>
			<Typography.Paragraph
				ellipsis={{ tooltip: data.title, rows: 1 }}
				style={{ margin: 0, textTransform: 'capitalize', textAlign: 'center' }}
			>
				{data.title || '---'}
			</Typography.Paragraph>
		</Card>
	);
};
