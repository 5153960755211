import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { languageOptions } from 'config/constants';
import { StorageService } from 'services/storage.service';
import { EStorageKeys } from 'types/enums';

type Props = {
	useGlobal?: boolean;
	initLanguage?: string;
	languageSetter?: (language: string) => void;
	disabledLanguage?: string[];
	className?: string;
};

const HeaderTranslation = ({ useGlobal = false, initLanguage, languageSetter, disabledLanguage, className }: Props) => {
	const { i18n } = useTranslation();

	const defaultLanguage = initLanguage ?? StorageService.get(EStorageKeys.TRANSLATION_LANGUAGE) ?? i18n.language;

	const handleLanguageChange = (value: string) => {
		if (useGlobal) {
			i18n.changeLanguage(value);
			StorageService.set(EStorageKeys.TRANSLATION_LANGUAGE, value);
			return;
		}

		languageSetter?.(value);
	};

	return (
		<Select
			defaultValue={defaultLanguage}
			onChange={handleLanguageChange}
			className={`${className ?? ''}  w-32`}
			options={languageOptions?.filter((e) => !disabledLanguage?.includes(e.value))}
		/>
	);
};

export default HeaderTranslation;
