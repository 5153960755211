import { Avatar, Flex, Typography } from 'antd';

import { FALLBACK_PROFILE_IMAGE_URL } from 'config/constants';
import { nameParser } from 'services/helpers.service';
import { EUserRoles, hashRoleIdToRoleTitle } from 'services/user-role/permissions/utils';

type Props = {
	imageUrl: string;
	firstName: string;
	lastName: string;
	roles: EUserRoles;
};
export const ProfilePhotoWithName = ({ imageUrl, lastName, firstName, roles }: Props) => {
	const name = nameParser(firstName, lastName);

	return (
		<Flex gap={8} align='center' className='w-full py-1'>
			<div className='w-8 h-8'>
				<Avatar src={imageUrl || FALLBACK_PROFILE_IMAGE_URL} />
			</div>

			<Flex vertical className='overflow-hidden'>
				<Typography.Text ellipsis={{ tooltip: true }} className='w-full capitalize m-0'>
					{name}
				</Typography.Text>
				<Typography.Text ellipsis={{ tooltip: hashRoleIdToRoleTitle[roles] }} className='capitalize m-0 text-[12px] text-[gray]'>
					{hashRoleIdToRoleTitle[roles]}
				</Typography.Text>
			</Flex>
		</Flex>
	);
};
