import { useCallback, useMemo, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { Form, Select, Spin } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { SelectProps } from 'antd/lib';

import { TNewSelectFieldProps } from 'components/input-fields/types/field-props';
import { debounce } from 'components/ui/search';
import { DEFAULT_ITEMS_LIMIT_PER_PAGE, DEFAULT_SORT_BY, DEFAULT_SORT_ORDER } from 'config/constants';
import { HttpService } from 'services/http.service';

const initPageParam = {
	q: '',
	sortBy: DEFAULT_SORT_BY,
	sort: DEFAULT_SORT_ORDER,
	limit: DEFAULT_ITEMS_LIMIT_PER_PAGE,
	page: 1
};

// this select component for pagination purposes.
const QuerySelect: React.FC<TNewSelectFieldProps> = ({ formItemProps, fieldProps, api }) => {
	const [pageParam, setPageParam] = useState(initPageParam);

	const [enabled, setEnabled] = useState(false);

	// fetch data
	const queryData = useInfiniteQuery({
		queryKey: [api.url, pageParam, 'QUERY_SELECT'],
		// eslint-disable-next-line @typescript-eslint/no-shadow
		queryFn: ({ pageParam }) => HttpService.get(api.url, pageParam).then(({ data }) => data?.data),
		initialPageParam: pageParam,
		getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
			if (lastPage?.hasNextPage) return { ...lastPageParam, page: Number(lastPage.currentPage) + 1 };
			return undefined;
		},
		enabled
	});

	const data: (BaseOptionType | DefaultOptionType)[] | undefined = useMemo(() => {
		const { labelKey, valueKey, getOptionLabel } = api;
		const excludeValues = api?.excludeValues || [];

		return queryData.data?.pages
			.map((data) => {
				const temp = data?.items || data;

				return temp?.map?.((item: any) => {
					const value = item?.[`${valueKey}`] || '';

					if (typeof excludeValues === 'function' && excludeValues(temp).includes?.(value)) return null;

					if (Array.isArray(excludeValues) && excludeValues?.includes?.(value)) return null;

					return {
						...item,
						value,
						label: getOptionLabel ? getOptionLabel(item) : `${item?.[`${labelKey}`] || ''}`
					};
				});
			})
			.flat()
			.filter((e) => e);
	}, [queryData.data?.pages]);

	const handleDebounce = debounce({ func: setPageParam, delay: 300 });

	const onSearch = (q: string) => {
		handleDebounce({ ...pageParam, q, page: 1 });
	};
	const handleClearSearch = () => {
		handleDebounce(initPageParam);
	};

	const handlePopupScroll: SelectProps['onPopupScroll'] = useCallback(
		(event: any) => {
			const { scrollHeight, scrollTop, clientHeight } = event.target as HTMLElement;

			const isBottom = scrollHeight - scrollTop <= clientHeight + 18;

			if (!isBottom || queryData.isFetchingNextPage) return;
			queryData.fetchNextPage();
		},
		[queryData.isFetchingNextPage]
	);

	return (
		<Spin spinning={queryData.isFetchingNextPage} className='w-full'>
			<Form.Item {...formItemProps}>
				<Select
					onClick={() => {
						setEnabled(true);
					}}
					labelInValue
					onPopupScroll={handlePopupScroll}
					size='large'
					allowClear
					showSearch
					options={data}
					onClear={handleClearSearch}
					onBlur={handleClearSearch}
					loading={queryData.isFetchingNextPage}
					notFoundContent={queryData.isFetchingNextPage ? <Spin size='small' /> : null}
					onSearch={onSearch}
					{...fieldProps}
				/>
			</Form.Item>
		</Spin>
	);
};

export default QuerySelect;
