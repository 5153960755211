import { Drawer, Dropdown, Flex, Pagination, Spin, Typography } from 'antd';

import CardGallery from 'components/card-gallery';
import BulkImageUploadDrawer from 'components/drawers/BulkImageUploadDrawer';
// eslint-disable-next-line import/no-cycle
import SingleImageUploadDrawer from 'components/drawers/SingleImageUploadDrawer';
import SearchComp from 'components/ui/search';
import { EImageType } from 'types/image';

import FilterButton from './FilterButton';
import { IMAGES_PER_PAGE, TImage, useImageGallery } from './useImageGallery';
import { TSelectedMedia } from '../types';

import './image-gallery.scss';

type TUploadModuleImageProps = {
	selectMultiple?: boolean;
	imageType?: `${EImageType}`;
	onImageSelected?: (images: TImage[]) => void; // TODO: to be removed
	onSelect?: (selectedMedias: TSelectedMedia[]) => void;
};

const HashImageTypeDrawerTitle: { [key in EImageType]: string } = {
	thumbnail: 'Thumbnail Images',
	header: 'Header Images',
	'card-long': 'Card Long Images',
	'card-wide': 'Card Wide Images'
};

const ImageGallery = ({ selectMultiple, imageType, onImageSelected, onSelect }: TUploadModuleImageProps) => {
	const {
		isLoading,
		dataList,
		showSingImageUpload,
		showBulkImageUpload,
		pagination,
		menuProps,
		sortOrderRef,
		totalImagesRef,
		onImageUploaded,
		onSingleImageModalClose,
		onFilterOptionSelected,
		onSearch,
		handlePageSelection,
		onBulkImageModalClose,

		selectedItemsId,
		handleItemSelect
	} = useImageGallery({ selectMultiple, imageType, onImageSelected, onSelect });

	return (
		<Spin spinning={isLoading}>
			<Flex vertical gap={16}>
				{/* header */}
				<Flex justify='space-between'>
					<Flex vertical>
						<Typography.Title level={4} style={{ margin: 0 }}>
							{HashImageTypeDrawerTitle[imageType as EImageType] || 'Images'}
						</Typography.Title>
						<Typography.Paragraph style={{ margin: 0 }}>Select Image</Typography.Paragraph>
					</Flex>

					<Flex gap={16}>
						<FilterButton sortOrder={sortOrderRef.current} selectedItem={onFilterOptionSelected} />

						<Dropdown.Button type='primary' size='large' menu={menuProps}>
							Add Image
						</Dropdown.Button>
					</Flex>
				</Flex>

				<SearchComp
					searchProps={{
						placeholder: 'Search Media'
					}}
					onValueChange={onSearch}
				/>

				{/* card list */}
				<CardGallery
					cardType='image'
					data={dataList}
					selectable
					selectedItems={selectedItemsId}
					onSelect={handleItemSelect}
				/>

				<Flex justify='center'>
					<Pagination
						defaultCurrent={pagination}
						total={totalImagesRef.current}
						pageSize={IMAGES_PER_PAGE}
						onChange={handlePageSelection}
						showSizeChanger={false}
					/>
				</Flex>

				<SingleImageUploadDrawer
					defaultImageType={imageType}
					onClose={onSingleImageModalClose}
					visible={showSingImageUpload}
					onImageUploaded={() => onImageUploaded()}
				/>

				<Drawer
					title='Upload Bulk Images'
					open={showBulkImageUpload}
					onClose={() => onBulkImageModalClose()}
					destroyOnClose
				>
					<BulkImageUploadDrawer
						defaultImageType={imageType}
						onClose={onBulkImageModalClose}
						onImagesUploaded={onImageUploaded}
					/>
				</Drawer>
			</Flex>
		</Spin>
	);
};

export default ImageGallery;
