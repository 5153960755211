export enum EInputFieldTypes {
	TEXT = 'text',
	NUMBER = 'number',
	TEXTAREA = 'textarea',
	SELECT = 'select',
	API_DRIVEN_SELECT = 'api-driven-select',
	SELECT_MEDIA = 'select-media',
	DATE_PICKER = 'date-picker',
	DATE_RANGE_PICKER = 'date-range-picker',
	TIME_PICKER = 'time-picker',
	TIME_RANGE_PICKER = 'time-range-picker',
	SWITCH = 'switch',
	CHECKBOX = 'checkbox',
	PILLS = 'pills',
	CUSTOM = 'custom',
	RADIO_GROUP = 'radio-group',
	TAG = 'tag',
	PASSWORD = 'password',
	IMAGE_FILE = 'image-file',
	QUERY_SELECT = 'query_select'
}

export enum EMediaTypes {
	IMAGE = 'image',
	VIDEO = 'video',
	AUDIO = 'audio'
}

export enum ETagTypes {
	IMAGE = 'image',
	AUDIO = 'audio',
	VIDEO = 'video',
	ARTICLE = 'article',
	PODCAST = 'podcast',
	MOVIE = 'movie',
	SERIES = 'series',
	POLL = 'poll'
}
